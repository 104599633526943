import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2020-stories/more-stories"
import StoryNav from "../../components/fall-winter-2020-stories/story-nav"

import Hero from '../../images/fall-winter-2020/presidents-message/hero-image2.jpg';
import framSignature from "../../images/fall-winter-2020/presidents-message/fram-signature.svg";
import socialBanner from "../../images/fall-winter-2020/social-banners/presidents-message.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'President’s Message';
    var pageDesc = 'During tough times, a Titan alumna spreads hope through feel-good holiday stories.';


    var heroStyles = {
      backgroundColor: '#222',
      backgroundImage: `url(${Hero})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
      backgroundSize: 'cover',
      color: 'white'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2020">
        <Helmet>
          <title>{pageTitle}</title>
          <body className="presidents-message" />


          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1>
          </div>
          <div className="tint"></div>
        </div>
        <article className="wrap small">

          <ScrollAnimation animateIn="fadeIn">
          <p><span className="intro-text">Considering what the Titan family achieved</span> this past spring — from a turn-on-the-dime transition to virtual modalities that bridged the digital divide with the dispersal of more than 1,000 laptops, MiFi hotspots and iPads, to a redoubling of our commitment to abolish privilege, eradicate systemic racism, and promote diversity, equity and inclusion far beyond the borders of our campus or region — a summer dip in productivity might be understandable, if not expected.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>Instead, on the heels of a virtual commencement celebration that recognized some of the highest GPAs, highest four-year graduation rates and lowest opportunity gaps in our 63-year history, our faculty, staff and students spent the next three months striving to get even better at teaching, learning and advocating for diversity, equity and inclusion in the “new normal.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>So when fall semester hit, the questions again resurfaced: What does it take to continue sprinting in what was clearly becoming a marathon? What does it take to add to and enhance the Titan family legacy of continually reaching higher?</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>As the semester unfolded, the answer to those questions became abundantly clear. It takes the perseverance and courage of students like Roxanne Aguirre who contracted COVID-19 on the front lines as a nurse assistant while continuing her academic journey toward becoming a registered nurse. It takes the faculty of the College of Education who are working to dismantle systemic racism and injustice in classrooms. It takes alumni like Nicole Rhoton, who is working with the U.S. Department of Defense to recover military personnel listed as prisoners of war or missing in action from past conflicts, giving the families of these fallen heroes closure and burial services with full military honors.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>In other words, It Takes a Titan. The faculty, staff, students and alumni featured in this issue of Titan magazine underscore the fact that in this unprecedented time and place, all of us can and must be the Titans that it takes — for each other, yes, but also for the state and nation who look to us for hope and inspiration. Indeed, we are the light at the end of the tunnel, and if we continue to persevere, press on and push through, we can and will illuminate the world.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p style={{lineHeight: '1.2em'}}>
            Sincerely,<br /><br />
            <img src={framSignature} alt="Fram Virjee" width="200px" /><br />
            Framroze M. Virjee, JD<br />
            President<br />
            California State University, Fullerton
          </p>
          </ScrollAnimation>

        </article>
        <MoreStories slug="presidents-message" />
        <StoryNav slug="presidents-message" />
      </Layout>
    );
  }
} 